<template>
    <div>
        <Loading v-if="loading && loading[$route.params.slug] !== false" />
        <Loading v-if="voteProcessing == true" />
        <div v-if="loading && loading[$route.params.slug] === false">

            <div class="grid grid-cols-4 gap-1 md:m-4 mt-3 mx-5" v-if="currentUser && currentUser.type == 'artist'">
                <div class="col-span-2">
                    <div class="font-bold text-xl flex items-center">
                        {{ currentUser.member_name }}
                        <img src="../../../assets/image/fire.svg" alt="" class="pl-1 w-5"
                            v-if="currentUser.isTopArtist == 'true'" />
                    </div>
                    <div class="flex" v-if="currentUser.trophy > 0">
                        <img src="../../../assets/image/trophy.svg" alt="" class="trophy w-6" />
                        <p class="pl-1">({{ currentUser.trophy }})</p>
                    </div>
                    <div class="flex bleez-text">
                        {{ currentUser.votes }}
                        <p class="pl-1">vote{{
                            currentUser.votes !== 1 ? "s" : ""
                        }}</p>
                    </div>

                    <div class="mt-2 text-sm">
                        <div class="flex lg:flex-col my-2 lg:relative"
                            v-if="currentUser.blast && currentUser.blast != 'null'">
                            <div>
                                The Blast APP:<span class="bleez-text font-bold pl-2" v-if="!showBlastInput">{{
                                    currentUser.blast
                                }}</span>
                            </div>
                            <div class="lg:w-full w-2/4 relative border-2 flex lg:m-0 ml-2" v-if="showBlastInput">
                                <input type="text" class="px-2 w-full z-0" name="blast" v-model="currentUser.blast" />
                                <img src="../../../assets/image/check.svg" alt="" class="font-bold px-2 w-8"
                                    @click="updateBlastId" />
                            </div>
                        </div>
                        <div class="flex lg:flex-col my-2 lg:relative"
                            v-if="currentUser.instagram && currentUser.instagram != 'null'">
                            <div class="flex w-full ">
                                Instagram:<a :href="'https://www.instagram.com/' + currentUser.instagram"
                                    class="bleez-text font-bold pl-2 truncate block" target="_new"
                                    v-if="!showInstagramInput">
                                    {{ currentUser.instagram }}</a>
                            </div>
                            <div class="lg:w-full w-2/4 relative border-2 flex lg:m-0 ml-2" v-if="showInstagramInput">
                                <input type="text" class="px-2 w-full z-0" name="instagram"
                                    v-model="currentUser.instagram" />
                                <img src="../../../assets/image/check.svg" alt="" class="font-bold px-2 w-8"
                                    @click="updateInstagramId" />
                            </div>
                        </div>
                        <div class="flex lg:flex-col my-2 lg:relative"
                            v-if="currentUser.soundcloud && currentUser.soundcloud != 'null'">
                            <div class="flex w-full">
                                Soundcloud:
                                <a :href="'https://soundcloud.com/' + currentUser.soundcloud"
                                    class="bleez-text font-bold pl-2 truncate block" target="_new"
                                    v-if="!showSoundcloudInput">
                                    {{ currentUser.soundcloud }} </a>
                            </div>

                            <div class="lg:w-full w-2/4 relative border-2 flex lg:m-0 ml-2" v-if="showSoundcloudInput">
                                <input type="text" class="px-2 w-full z-0" name="soundcloud"
                                    v-model="currentUser.soundcloud" />
                                <img src="../../../assets/image/check.svg" alt="" class="font-bold px-2 w-8"
                                    @click="updateSoundcloudId" />
                            </div>
                        </div>
                        <div class="w-full lg:flex-col flex my-2 lg:relative"
                            v-if="currentUser.youtube && currentUser.youtube != 'null'">
                            <div class="flex w-full ">
                                Youtube:<a :href="'https://www.youtube.com/' + currentUser.youtube"
                                    class="bleez-text font-bold pl-2 truncate block" target="_new" v-if="!showYoutubeInput">
                                    {{ currentUser.youtube }}</a>
                            </div>
                            <div class="lg:w-full w-2/4 relative border-2 flex lg:m-0 ml-2" v-if="showYoutubeInput">
                                <input type="text" class="px-2 w-full z-0" name="youtube" v-model="currentUser.youtube" />
                                <img src="../../../assets/image/check.svg" alt="" class="font-bold px-2 w-8"
                                    @click="updateYoutubeId" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-span-2 ml-10 place-self-center">
                    <div class="relative md:w-full h-full image-holder overflow-hidden ">
                        <img src="@/assets/image/white-bg.png" class="absolute" style="max-height: 102%;max-width: 102%;margin-top: -1px;"/>

                        <img :src="previewImageUrl" alt="" v-if="showPreviewImage" />
                        <img src="@/assets/image/default.png" alt="" v-else-if="!currentUser || currentUser.image == null || currentUser.image == ''
                            " />
                        <img onerror="this.onerror=null;this.src=document.getElementById('defaultImage').src;"
                            :src="media_base_url + currentUser.image" class="h-full w-full profile-image" alt="" v-else />

                        <div class="absolute bottom-4 right-12"
                            v-if="user && user.public_id && user.public_id == currentUser.public_id">
                            <router-link :to="{ name: 'EditProfile' }" class="text-white px-2 py-0 rapbg rounded-lg">
                                Edit
                            </router-link>
                        </div>

                        <div class="grid grid-cols-3 gap-2 absolute md:bottom-5 bottom-5 w-full px-2">
                            <div>
                                <button class="signup-btn rounded-md text-normal px-2" v-if="showPreviewImage"
                                    @click="cancelPreviewImage">
                                    Cancel
                                </button>
                            </div>
                            <div class="text-center">
                                <button class="signup-btn rounded-md text-normal px-2" v-if="showPreviewImage"
                                    @click="changeProfileImage">
                                    Upload
                                </button>
                            </div>
                            <div class="text-right">
                                <button class="text-white px-5 py-1 rapbg rounded-lg font-black" v-if="loading &&
                                    loading[$route.params.slug] === false &&
                                    user &&
                                    user.public_id &&
                                    user.public_id != currentUser.public_id &&
                                    user.state[0].public_id == currentUser.state_public_id
                                    " @click="vote()" v-show="!isVoted" :disabled="voteProcessing">
                                    Vote
                                </button>
                                <button class="text-white px-5 py-1 bg-gray-400 rounded-lg font-black cursor-not-allowed"
                                    v-if="loading &&
                                        loading[$route.params.slug] === false &&
                                        user &&
                                        user.public_id &&
                                        user.public_id != currentUser.public_id &&
                                        user.state[0].public_id == currentUser.state_public_id
                                        " v-show="isVoted">
                                    Vote
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>

                <div :class="{
                    'text-right relative': true,
                    'cprofile-menu':
                        currentRouteName == 'CProfileWithSlug' &&
                        user.public_id == currentUser.public_id,
                    // 'mr-1': currentRouteName != 'CProfileWithSlug',
                }" @click="show = !show">
                    <button class="" v-if="user && user.public_id && user.public_id == currentUser.public_id">
                        <img src="../../../assets/image/more.png" alt="" class="h-2 mr-5" />
                    </button>
                    <div v-if="currentRouteName != 'CProfileWithSlug'" :class="{
                        'border-b-2 mx-4 md:mx-4 mt-3 mb-2': true,
                        'border-black': activeTab != 'AddToProfile',
                        'border-gray-300': activeTab == 'AddToProfile',
                    }"></div>
                    <div class="mt-2">
                        <ul class="dropdown-menu absolute mr-3 right-0 top-8 text-white bg-gray-400 rounded-lg text-left z-50"
                            v-if="show">
                            <li v-if="user.type == 'artist'">
                                <router-link
                                    class="rounded-t py-2 px-4 block whitespace-no-wrap lg:text-xl md:text-2xl text-xl"
                                    :to="{ name: 'PurchaseHistory' }">
                                    View purchase history
                                </router-link>
                            </li>
                            <li v-if="user.type != 'artist'">
                                <router-link
                                    class="rounded-t py-2 px-4 block whitespace-no-wrap lg:text-xl md:text-2xl text-xl"
                                    :to="{ name: 'VotingHistory' }">
                                    View voting history
                                </router-link>
                            </li>
                            <li v-if="user.type == 'artist'">
                                <a class="py-2 px-4 block whitespace-no-wrap lg:text-xl md:text-2xl text-xl" id="AddMusic"
                                    @click="userSelectedTab = 'AddMedia'">
                                    Add video
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="currentUser">
                    <div class="mb-8">
                        <Comments :comments="currentUser.comments" v-if="currentUser.comments.pagination.total > 0"
                            :currentUser="currentUser" @onDelete="deleteComment" />
                    </div>
                    <div>
                        <AddToProfile :currentProfileUserId="currentUser.public_id" @changePlays="updatePlays" @vote="vote"
                            :isVoted="isVoted" :voteProcessing="voteProcessing"
                            :userState="user && user.state && user.state[0].public_id"
                            :currentProfileState="currentUser.state_public_id" v-if="activeTab == 'AddToProfile'" />
                    </div>
                    <div>
                        <AddMedia @mediaAdded="mediaAdded" v-if="activeTab == 'AddMedia'" />
                    </div>
                    <!-- <div v-if="paymentHistory">
                        <PaymentHistory />
                    </div>
                    <div v-if="subscribePlan">
                        <MakePayment></MakePayment>
                    </div> -->
                    <div v-if="activeTab == 'VotingHistory'">
                        <VotingHistory></VotingHistory>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import AddToProfile from "./AddToProfile";
// import PaymentHistory from "./PaymentHistory";
import AddMedia from "./AddMedia";
// import MakePayment from "./MakePayment";
import VotingHistory from "./VotingHistory";
import { mapGetters } from "vuex";
import Storage from "@/helper/Storage";
import Loading from "../../Shared/Loading";
import Comments from "./Comments";

export default {
    name: "Profile",
    components: {
        AddToProfile,
        AddMedia,
        // PaymentHistory,
        // MakePayment,
        VotingHistory,
        Loading,
        Comments
    },
    data() {
        return {
            media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
            input: true,
            show: false,
            showYoutubeInput: false,
            showBlastInput: false,
            showInstagramInput: false,
            showSoundcloudInput: false,
            token: null,
            // currentUser: false,
            profileUpdate: {
                public_id: null,
                member_name: null,
                type: null,
                state_public_id: null,
                blast: null,
                instagram: null,
                soundcloud: null,
                youtube: null,
            },
            myProfile: false,
            currentroute: "",
            // isVoted: false,
            profileImage: null,
            previewImageUrl: null,
            showPreviewImage: false,
            toast: useToast(),
            userSelectedTab: false,
            // loading: true,
            voteProcessing: false,
        };
    },
    created() {
        this.init();
        // console.log(this.$store.getters["Profile/loading"][this.$route.params.slug]);
    },
    computed: {
        ...mapGetters({ user: "User/user" }),
        currentRouteName() {
            return this.$route.name;
        },
        currentUser() {
            return this.$store.getters["Profile/currentUser"][this.$route.params.slug];
        },
        isVoted() {
            return this.$store.getters["Profile/isVoted"][this.$route.params.slug];
        },
        loading() {
            return this.$store.getters["Profile/loading"];
        },
        activeTab() {
            if (
                !this.userSelectedTab &&
                this.user.type == "artist" &&
                this.user.public_id == this.currentUser.public_id
            ) {
                if (this.user.artist_songs_count > 0) {
                    return "AddToProfile";
                } else {
                    return "AddMedia";
                }
            } else {
                if (!this.userSelectedTab) {
                    if (this.user.type == "user") {
                        return "AddToProfile";
                    }
                    if (this.currentUser && this.user.public_id != this.currentUser.public_id) {
                        return "AddToProfile";
                    }
                }
                return this.userSelectedTab;
            }
        },
    },
    methods: {
        init() {
            let url =
                this.user && this.user.public_id ? "get-artist-info/" : "guest-get-artist-info/";
            API.get(url + this.$route.params.slug).then((response) => {
                // this.loading = false;
                // this.currentUser = response.artist;
                // this.currentUser.plays = response.plays;
                // this.currentUser.votes = response.votes;
                // this.currentUser.isTopArtist = response.is_top_artist;
                // this.currentUser.trophy = response.trophy;
                // this.isVoted = response.is_voted == "true" ? true : false;
                response.is_voted = response.is_voted == "true" ? true : false;
                this.$store.commit("Profile/SET_ARTIST", response);
                // this.$store.commit("Profile/SET_PLAYS", response.plays);
                // this.$store.commit("Profile/SET_VOTES", response.votes);
                // this.$store.commit("Profile/SET_ISTOPARTIST", response.is_top_artist);
                // this.$store.commit("Profile/SET_TROPHY", response.trophy);
                // this.$store.commit("Profile/SET_IS_VOTED", );
                this.$store.commit("Profile/SET_LOADING", {
                    status: false,
                    slug: this.$route.params.slug,
                });
            });
        },
        deleteComment() {
            this.init()
        },
        // toggleMenu() {
        //   this.show = !this.show;
        // },
        // updateBlastId() {
        //   this.showBlastInput = !this.showBlastInput;
        //   this.updateData();
        // },
        // updateInstagramId() {
        //   this.showInstagramInput = !this.showInstagramInput;
        //   this.updateData();
        // },
        // updateSoundcloudId() {
        //   this.showSoundcloudInput = !this.showSoundcloudInput;
        //   this.updateData();
        // },
        // updateYoutubeId() {
        //   this.showYoutubeInput = !this.showYoutubeInput;
        //   this.updateData();
        // },
        // updateData() {
        //   API.post("profile-update", {
        //     member_name: this.currentProfileData.member_name,
        //     type: this.currentProfileData.type,
        //     state_public_id: this.currentProfileData.state.public_id,
        //     blast: this.currentProfileData.blast,
        //     instagram: this.currentProfileData.instagram,
        //     soundcloud: this.currentProfileData.soundcloud,
        //     youtube: this.currentProfileData.youtube,
        //   }).then((response) => {
        //     // console.log(response);
        //     var updateUser = this.user;
        //     updateUser.blast = response.user.blast;
        //     updateUser.instagram = response.user.instagram;
        //     updateUser.soundcloud = response.user.soundcloud;
        //     updateUser.youtube = response.user.youtube;
        //     this.$store.commit("User/SET_USER", updateUser);
        //     this.toast.success(response.message);
        //   });
        // },
        vote(song_public_id) {
            this.voteProcessing = true;
            // console.log(this.user.public_id);
            API.post("vote", {
                artist_public_id: this.currentUser.public_id,
                song_public_id: song_public_id,
            }).then((response) => {
                // console.log(response)
                this.voteProcessing = false;
                if (response.message == "Vote successfully submitted.") {
                    // this.currentUser.votes = response.votes;
                    this.toast.success(response.message);
                    // this.isVoted = true;
                    response.isVoted = true;
                    response.votes;
                    response.slug = this.$route.params.slug;
                    this.$store.commit("Profile/SET_ISVOTED", response);
                }
            });
        },
        addAttachment() {
            this.$refs.profileImage.click();
            this.showPreviewImage = true;
        },
        makePreviewImage() {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.previewImageUrl = e.target.result;
            };
            reader.readAsDataURL(this.$refs.profileImage.files[0]);
        },
        cancelPreviewImage() {
            this.showPreviewImage = false;
            this.previewImageUrl = "";
        },
        changeProfileImage() {
            this.profileImage = this.$refs.profileImage.files[0];

            console.log(this.profileImage);

            var formData = new FormData();

            if (this.profileImage) {
                formData.append("image", this.profileImage);

                const config = {
                    headers: { "content-type": "multipart/form-data" },
                };

                API.post("profile-image-upload", formData, config).then((response) => {
                    if (response.message == "Profile image added successfully!") {
                        this.currentProfileData.image = response.image;
                        this.showPreviewImage = false;
                        this.previewImageUrl = "";
                    }
                });
            }
        },
        updatePlays(e) {
            this.currentProfileData.plays = e;
        },
        mediaAdded() {
            this.userSelectedTab = false;
        },
        // changeProfileData(e) {
        //   console.log(this.currentProfileData);
        //   this.currentUser.member_name = e.member_name;
        //   this.currentUser.blast = e.blast;
        //   this.currentUser.youtube = e.youtube;
        //   this.currentUser.instagram = e.instagram;
        //   this.currentUser.soundcloud = e.soundcloud;
        // },
        goBack() {
            var route = Storage.Get("PREVIOUS_ROUTE");
            if (route == "Login" || route != "Login") {
                this.$router.push({ name: "TopRappers" });
            }
        },
    },
};
</script>

<template>
    <div>
        <div v-for="(comment, index) in comments.data" :key="index" class="mx-5 text-sm border-b pt-1 pb-6 relative">
            <Loading v-if="processing == comment.id" />
            <div class="mb-1 text-right" v-if="user.id == comment.user_id || currentUser.id == comment.artist_id">
                <div class="navbar-right  relative">
                    <button @click="isOpen = comment.id">
                        <img src="../../../assets/image/more.png" alt="" class="h-1 opacity-40" />
                    </button>
                    <button v-if="isOpen == comment.id" @click="isOpen = false" tabindex="-1"
                        class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
                    <div v-if="isOpen == comment.id"
                        class="absolute top-auto right-0 w-24 mt-1 rounded-lg border-gray-900 bg-white shadow-xl z-20">
                        <button type="button" @click="deleteComment(comment)"
                            class="text-sm text-gray-900 hover:bg-gray-400 hover:text-white w-full py-2 cursor-pointer">Delete</button>
                    </div>
                </div>
            </div>
            <div class="mb-5 leading-5">
                {{ comment.comment }}
            </div>
            <div class="flex justify-between">
                <div class="text-xs opacity-40">{{ comment.created_at }}</div>
                <router-link :to="{ name: 'CProfileWithSlug', params: { slug: comment.user.slug } }" class="bleez-text"
                    v-if="currentRouteName == 'ProfileWithSlug'">@{{ comment.user.name }}</router-link>
                <router-link :to="{ name: 'ProfileWithSlug', params: { slug: comment.artist.slug } }" class="bleez-text"
                    v-if="currentRouteName == 'CProfileWithSlug'">@{{ comment.artist.name }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import API from "../../../api/http";
import Storage from "@/helper/Storage";
import Loading from "../../Shared/Loading";

export default {
    name: "Comments",
    components: {
        Loading
    },
    props: ['comments'],
    data() {
        return {
            isOpen: false,
            processing: false,
        };
    },
    created() {
        // this.init();
    },
    computed: {
        ...mapGetters({ user: "User/user" }),
        currentUser() {
            return this.$store.getters["Profile/currentUser"][this.$route.params.slug];
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    methods: {
        deleteComment(comment) {
            this.isOpen = false;
            this.processing = comment.id;
            
            API.post(`comments/${comment.id}`, {
                _method: 'delete'
            }).then((response) => {
                this.$emit('onDelete');
                this.processing = false;
                if (response.message == "Vote successfully submitted.") {
                    this.toast.success(response.message);
                    // this.$store.commit("Profile/SET_ISVOTED", response);
                }
            });
        },
        goBack() {
            var route = Storage.Get("PREVIOUS_ROUTE");
            if (route == "Login" || route != "Login") {
                this.$router.push({ name: "TopRappers" });
            }
        },
    },
};
</script>
